import React from 'react';

import { Col, Row } from 'reactstrap';
import { Flex, FlexRow, Navbar } from '../components';
import BaseLayout from '../components/Layouts/BaseLayout';
import useNavigationScroller from '../hooks/useNavigationScroller';
import SideDiamond from '../components/SideDiamond/SideDiamond';
import LargeBar from '../components/Bars/LargeBar';

import PartnersFooter from '../components/PartnersFooter/PartnersFooter';
import { Link } from 'gatsby';
import Markdown from '../components/Markdown/Markdown';
import faqDataJson from '../data/faq.json';

export const FaqPageInternal = (faqData: typeof faqDataJson) => {
  const wrapper = React.useRef<HTMLDivElement>(null);
  useNavigationScroller(wrapper);

  return (
    <BaseLayout title="FAQ">
      <Navbar />

      <Row className="flex-fill">
        <Col md={4}></Col>
        <Col className="d-flex flex-column p-3 flex-fill" md={8}>
          <div className="pt-2 px-2">
            <Link to="/">
              <img className="img-fluid hero-opacity" src={require('../images/logo-horizontal.svg').default} alt="Aviation Safety Solutions" style={{ height: '20vh' }} />
            </Link>
          </div>
        </Col>
      </Row>

      <div className="wrapper" ref={wrapper}>
        <Row>
          <Col className="d-none d-md-block" md={4}>
            <Flex center className="side-affix">
              <SideDiamond label={<span>Faq</span>} icon={null} />
            </Flex>
          </Col>
          <Col lg={8}>
            <section className="px-2 pt-5 body-bg">
              <Markdown source={faqData.mainMarkdown} />
            </section>
          </Col>
        </Row>

        <div className="hero-opacity my-1 mt-5 text-center">
          <LargeBar style={{ width: '60%' }} />
        </div>

        <section className="pt-5">
          <PartnersFooter />
        </section>

        <div className="hero-opacity mt-3 pb-5 text-center text-secondary">
          <FlexRow justifyCenter childSpacingX={1}>
            <i className="mdi mdi-36px mdi-star" />
            <i className="mdi mdi-36px mdi-star" />
            <i className="mdi mdi-36px mdi-star" />
            <i className="mdi mdi-36px mdi-star" />
            <i className="mdi mdi-36px mdi-star" />
          </FlexRow>
        </div>
      </div>
    </BaseLayout>
  );
};

export default () => <FaqPageInternal {...faqDataJson} />;
